<template>
  <div style="width: 100%; height: 100%; position: relative">
    <h-table
      :options="options"
      :data="table.data"
      :columns="table.columns"
      :loading="table.loading"
      @page-change="pageChange"
      @search="search"
    >
    </h-table>
    <order-modal
      v-model="orderModalData.show"
      :supply-demand="orderModalData.data"
      :farms="farms"
    ></order-modal>
    <order-list :order-no="orderListData.orderNo" v-model="orderListData.show">
    </order-list>
    <talk-modal v-model="talkModalData.show"></talk-modal>
    <div style="position: absolute; left: 1260px; top: 16px">
      <Button type="primary" @click="loadExpend">导出</Button>
    </div>
  </div>
</template>
<script>
import { Poptip } from "view-design";
import OrderModal from "./orderModal";
import orderList from "./orderList";
import talkModal from "@/components/talkModal";
import CU from "@/common/util";

export default {
  components: {
    OrderModal,
    orderList,
    talkModal,
  },
  data() {
    return {
      table: {
        data: [],
        columns: [
          {
            title: "发布时间",
            width: 180,
            key: "createTime",
          },
          {
            title: "标题",
            key: "demandName",
          },

          {
            title: "供需类型",
            width: 100,
            key: "demandType",
            render: (h, { row }) => {
              let str = row.demandType == 1 ? "采购需求" : "供应需求";
              return <span>{str}</span>;
            },
          },
          {
            title: "联系人",
            width: 100,
            key: "telName",
            render: (h, { row }) => {
              return <span>{row.telName == "" ? "--" : row.telName}</span>;
            },
          },
          {
            title: "联系电话",
            width: 130,
            key: "telPhone",
            render: (h, { row }) => {
              return <span>{row.telPhone == "" ? "--" : row.telPhone}</span>;
            },
          },
          {
            title: "地址信息",
            key: "demandAddress",
            ellipsis: true,
            tooltip: true,
          },

          {
            title: "供需状态",
            width: 100,
            key: "demandStatus",
            render: (h, { row }) => {
              let str =
                row.demandStatus == 1
                  ? "已发布"
                  : row.demandStatus == 2
                  ? "已结束"
                  : "--";
              return <span>{str}</span>;
            },
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              let userId = localStorage.getItem("userId");

              return (
                <div style="display:flex;align-items:center">
                  {this.CA("demandManage_finish") &&
                    row.demandStatus == 1 &&
                    row.createUserId == userId && (
                      <span style="padding:0 4px">
                        <Poptip
                          confirm
                          transfer
                          title="确定要结束这条信息吗？"
                          on-on-ok={() => this.finish(row)}
                        >
                          <a>结束</a>
                        </Poptip>
                      </span>
                    )}
                  {this.CA("demandManage_delete") &&
                    row.demandStatus == 1 &&
                    row.createUserId == userId && (
                      <span style="padding:0 4px">
                        <Poptip
                          confirm
                          transfer
                          title="确定要撤销这条信息吗？"
                          on-on-ok={() => this.deleteRow(row)}
                        >
                          <a>撤销</a>
                        </Poptip>
                      </span>
                    )}
                  {row.demandStatus == 1 && row.createUserId == userId && (
                    <span style="padding:0 4px">
                      <a on-click={() => this.checkOrderList(row)}>
                        预下单列表
                      </a>
                    </span>
                  )}
                </div>
              );
            },
          },
        ],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      // 提交中
      submitLoading: false,
      model: {
        demandType: "",
        demandName: "",
        productId: "",
        demandDesc: "",
        demandRemark: "",
        demandNum: "",
        demandUnit: "",
        demandImgs: "",
        demandAddress: "",
        telPhone: "",
        telName: "",
        publisherFarmId: "",
      },
      rules: {
        demandName: {
          col: 24,
          name: "需求描述",
          type: "t",
          required: true,
        },
        demandType: {
          col: 12,
          name: "供需类型",
          type: "s",
          required: true,
          data: [
            {
              value: "0",
              label: "供应需求",
            },
            {
              value: "1",
              label: "采购需求",
            },
          ],
        },
        publisherFarmId: {
          col: 12,
          name: "生产基地",
          type: "s",
          data: this.getFarms,
          required: true,
        },
        demandAddress: {
          col: 12,
          name: "供需地址",
          type: "i",
        },
        productId: {
          col: 12,
          name: "产品",
          type: "s",
          data: this.getProducts,
        },
        demandImgs: {
          col: 24,
          name: "图片介绍",
          type: "image",
        },
        // demandNum: {
        //   col: 12,
        //   name: "供需数量",
        //   type: "i",
        //   rules: [
        //     {
        //       validator(rule, value, callback) {
        //         if (value === "" || value == undefined) callback();
        //         else if (CU.validateNumber(value)) callback();
        //         else callback(rule.message);
        //       },
        //       message: "请填写数字",
        //     },
        //   ],
        // },
        // demandUnit: {
        //   col: 12,
        //   name: "数量单位",
        //   type: "i",
        // },
        // telName: {
        //   col: 12,
        //   name: "联系人",
        //   type: "i",
        // },
        // telPhone: {
        //   col: 12,
        //   name: "联系电话",
        //   type: "i",
        // },
        // demandDesc: {
        //   name: "产品描述",
        //   type: "t",
        // },
        // demandRemark: {
        //   name: "备注",
        //   type: "t",
        // },
      },
      searchData: {
        demandType: "",
        demandStatus: "1",
      },
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },

      farms: [],
      products: [],

      // 订单弹窗数据
      orderModalData: {
        data: null,
        show: false,
      },
      // 预订单弹窗
      orderListData: {
        orderNo: "",
        show: false,
      },
      // 聊天弹窗
      talkModalData: {
        show: false,
      },
    };
  },
  computed: {
    options() {
      let options = {
        form: {
          width: 720,
          model: this.model,
          rules: this.rules,
        },
        search: {
          model: this.searchData,
          rules: {
            demandType: {
              name: "供需类型",
              type: "s",
              data: [
                {
                  value: "0",
                  label: "供应需求",
                },
                {
                  value: "1",
                  label: "采购需求",
                },
              ],
            },
            demandStatus: {
              name: "供需状态",
              type: "s",
              data: [
                {
                  value: "1",
                  label: "已发布",
                },
                {
                  value: "2",
                  label: "已结束",
                },
              ],
            },
            telPhone: {
              name: "联系电话",
              type: "i",
            },
            telName: {
              name: "联系人",
              type: "i",
            },
          },
        },
        page: this.page,
        add: {
          text: "新增供需信息",
          submit: this.add,
          beforeAdd: () => {
            this.farms.length > 0 &&
              (this.model.publisherFarmId = this.farms[0].id);
          },
          ca: "demandManage_add",
        },
        //  loadExpend: {
        //    text: "导出",
        //     // loadExpendName: "导出",
        //   },
      };
      return options;
    },
  },
  watch: {
    "model.publisherFarmId": {
      handler(id) {
        if (!id) return;
        let farm = this.farms.find((item) => item.id == id);
        if (!farm) return;
        this.model.demandAddress = farm.address;
      },
    },
  },
  methods: {
    // 沟通
    talk(row) {
      this.$store.dispatch("startTalk", row.createUserId);
      this.talkModalData.show = true;
    },
    // 下单
    sendOrder(row) {
      this.orderModalData.data = { ...row };
      this.orderModalData.show = true;
    },
    // 查看预下单列表
    checkOrderList(row) {
      this.orderListData.orderNo = row.id;
      this.orderListData.show = true;
    },
    //   添加
    add(callback) {
      this.$post(this.$api.SUPPLY_BIND.ADD, {
        ...this.model,
      })
        .then(() => {
          this.$Message.success("发布成功");
          this.getList();
          callback();
        })
        .catch(() => {
          this.$Message.error("发布失败");
          callback(false);
        });
    },
    // 列表
    getList() {
      if (!this.CA("demandManage_check")) return;
      this.table.loading = true;
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        myAdd: "1",
        ...this.searchData,
      })
        .then((res) => {
          this.page.total = +res.total;
          this.table.data = res.list;
        })
        .catch(() => {
          this.table.data = [];
          this.page.total = 0;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    // 撤销
    deleteRow(row) {
      this.$post(this.$api.SUPPLY_BIND.DELETE, {
        id: row.id,
      })
        .then(() => {
          this.$Message.success("成功撤销");
          this.getList();
        })
        .catch(() => {
          this.$Message.warning("撤销失败");
        });
    },
    // 结束供需
    finish(row) {
      this.$post(this.$api.SUPPLY_BIND.FINISH, {
        id: row.id,
      })
        .then(() => {
          this.$Message.success("供需完结");
          this.getList();
        })
        .catch(() => {
          this.$Message.warning("结束失败");
        });
    },
    // 分页改变
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    // 查询
    search() {
      this.getList();
    },
    // 查询基地
    getFarms() {
      return new Promise((resolve) => {
        this.$post(this.$api.FAMRMING.FARMLIST)
          .then((res) => {
            this.farms = res.list;
            let result = res.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            resolve(result);
          })
          .catch(() => {
            this.farms = [];
            resolve([]);
          });
      });
    },
    // 查询产品列表
    getProducts() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCT_INFO.LIST, {
          pageNo: 1,
          pageSize: 9999,
          catId: "8",
        })
          .then((res) => {
            this.products = res.list;
            let result = res.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            resolve(result);
          })
          .catch((e) => {
            this.products = [];
            resolve([]);
          });
      });
    },
    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      columns.pop();
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.title == "供需类型") {
            return row.demandType == 1 ? "采购需求" : "供应需求";
          } else if (column.title == "供需状态") {
            return row.demandStatus == 1
              ? "已发布"
              : row.demandStatus == 2
              ? "已结束"
              : "--";
          }
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "需求管理");
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
