<template>
  <Modal
    :width="720"
    footer-hide
    title="预订单列表"
    :value="value"
    @input="(val) => $emit('input', val)"
  >
    <div>
      <h-table
        size="small"
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
      >
      </h-table>
    </div>
  </Modal>
</template>
<script>
import { Poptip } from "view-design";

export default {
  props: {
    value: Boolean,
    orderNo: [String, Number],
  },
  data() {
    return {
      table: {
        data: [],
        loading: false,
        columns: [
          {
            title: "标题",
            key: "name",
          },
          {
            title: "下单地址",
            key: "receiveAddress",
          },
          {
            title: "备注",
            key: "remark",
          },
          {
            title: "下单时间",
            key: "orderTime",
            width: 150,
          },
          {
            title: "操作",
            render: (h, { row }) => {
              return (
                <span>
                  <Poptip
                    confirm
                    transfer
                    title="确认订单吗?"
                    on-on-ok={() => this.confirmOrder(row)}
                  >
                    <a>订单确认</a>
                  </Poptip>
                </span>
              );
            },
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getOrderList();
      } else {
        this.table.data = [];
        this.table.loading = false;
      }
    },
  },
  methods: {
    //   查询订单列表
    getOrderList() {
      if (!this.orderNo) return;
      this.table.loading = true;
      this.$post(this.$api.ORDER_INFO.LIST, {
        pageNo: 1,
        pageSize: 999999,
        orderNo: this.orderNo,
        orderStatus: "0",
      })
        .then((res) => {
          this.table.data = res.list;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    // 订单确定
    confirmOrder(row) {
      this.$post(this.$api.ORDER_INFO.CONFIRM, {
        id: row.id,
        confirm:'true'
      }).then(() => {
        this.$Message.success("订单已确认");
        this.getOrderList();
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>